<script setup>
const homeStore = useHomeStore();
</script>

<template>
  <div class="flex-shrink-0 max-w-2xl mx-auto lg:mx-0 lg:max-w-xl lg:pt-8">
    <!-- <div class="mt-24 sm:mt-32 lg:mt-16">
      <a href="#" class="inline-flex space-x-6">
        <span
          class="px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 rounded-full bg-indigo-500/10 ring-1 ring-inset ring-indigo-500/20"
          >What's new</span
        >
        <span
          class="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
          <span>Just shipped v1.0</span>
          <ChevronRightIcon class="w-5 h-5 text-gray-500" aria-hidden="true" />
        </span>
      </a>
    </div> -->
    <h1 class="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
      {{ $t("home-heading") }}
    </h1>
    <p class="mt-6 text-lg leading-8 text-gray-300">
      {{ $t("home-description") }}
    </p>
    <p class="mt-8 text-base font-semibold text-white sm:mt-10">
      {{ $t("our-integrations") }}
    </p>
    <div class="w-full mt-5 sm:mx-auto sm:max-w-lg lg:ml-0">
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center justify-center px-1">
          <img
            src="https://s2.coinmarketcap.com/static/img/exchanges/32x32/270.png"
            alt="Binance" />
          <h2
            class="px-2 text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
            Binance
          </h2>
        </div>
        <div class="flex items-center justify-center px-1">
          <img
            src="https://images.ctfassets.net/q5ulk4bp65r7/3TBS4oVkD1ghowTqVQJlqj/2dfd4ea3b623a7c0d8deb2ff445dee9e/Consumer_Wordmark.svg"
            height="20"
            width="112"
            alt="Coinbase Logo"
            class="LogoWrapper__Logo-sc-1b8e13a-2 fSFCDH" />
        </div>
        <div class="flex justify-center px-1">
          <LazyFiriIcon class="h-6 sm:h-8" />
        </div>
      </div>
    </div>
    <div class="flex items-center mt-10 gap-x-6">
      <NuxtLink
        to="/login"
        @click="homeStore.signIn = false"
        class="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
        >{{ $t("get-started") }}</NuxtLink
      >
      <!-- <a href="#" class="text-sm font-semibold leading-6 text-white"
        >Learn more <span aria-hidden="true">→</span></a
      > -->
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
